/* CSS */
import "@js/parts/forms";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

import Forms from '@js/modules/Forms';

window.MCCAFERYFORMS =
    window.MCCAFERYFORMS ||
    new (function MCCAFERYFORMS() {

        this.modules = {
            Forms: Forms,
        };

    })();
