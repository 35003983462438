/* Imports */
import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

class Forms {
    constructor() {
        this.attr = 'data-formie';
        this.FormieInstance = new Formie();
    }

    init() {
        this.FormieInstance.initForms(false);

        document.addEventListener('onFormieInit', (event) => {
            // Fetch the Form Factory once it's been loaded
            let form = event.detail.formie;
            let formId = event.detail.formId;
            let formEl = document.getElementById(formId);

            // Refresh the necessary bits that are statically cached (CSRF inputs, captchas, etc)
            this.update(form, formId);

            if (formEl.getAttribute('data-form-submit-method') !== 'ajax') {
                formEl.addEventListener('onAfterFormieSubmit', (e) => {
                    //e.preventDefault();

                    this.update(form);
                });
            }
        });
    }

    update(form, formId) {
        form.refreshForCache(formId);
    }

    destroyForms() {
        if (this.FormieInstance) {
            let formEls = document.querySelectorAll(`[${this.attr}]`);

            formEls.forEach((form) => {
                this.FormieInstance.destroyForm(form);
            });
        }
    }

    refresh() {
        let formEls = document.querySelectorAll(`[${this.attr}]`);

        formEls.forEach((form) => {
            const formHandle = form.getAttribute(this.attr);

            // Find the CSRF token hidden input, so we can replace it
            let csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

            // Fetch the new token for the form and replace the CSRF input with our new one
            fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
                .then(result => { return result.json(); })
                .then(result => {
                    csrfInput.outerHTML = result.csrf.input;

                    // Find the JavaScript captcha hidden input, so we can update it
                    if (result.captchas && result.captchas.javascript) {
                        // JavaScript captcha
                        let jsCaptcha = result.captchas.javascript;

                        form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
                    }

                    // Find the Duplicate captcha hidden input, so we can update it
                    if (result.captchas && result.captchas.duplicate) {
                        // Duplicate captcha
                        let duplicateCaptcha = result.captchas.duplicate;

                        form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                    }

                    // Update the form's hash (if using Formie's themed JS)
                    if (form.form && form.form.formTheme) {
                        form.form.formTheme.updateFormHash();
                    }
                });
        });
    }
}

export default new Forms();
